import React, { useState } from "react";
import CompanyDirectoriesListing from "../../components/CompaniesDirectories/CompanyDirectoriesListing";
import { useParams } from "react-router-dom";

const Directories = () => {
  const { companyId } = useParams();

  return (
    <div>
      <CompanyDirectoriesListing companyId={companyId} />
    </div>
  );
};

export default Directories;
