import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { BULK_LISTING_UPLOAD } from "../../graphql/listingQueries";
import { useToast } from "../../components/Toast/toastHook";
import { GET_COMPANY_LISTINGS } from "../../graphql/listingQueries";
import { Button, Col, Modal, Row, Select } from "antd";

const ACCEPTED_COLUMNS = [
  "Expiration",
  "StreetAddr",
  "City",
  "State",
  "Zip",
  "PropertyType",
  "Agent",
  "Price",
  "Beds",
  "Baths",
  "Acreage",
  "BuyerCommission",
  "LotSize",
  "Notes",
  "YearBuilt",
  "SquareFeet",
];

function getInitialMappedColumns() {
  return ACCEPTED_COLUMNS.map((c) => ({ actual: c, mapped: null }));
}

const BulkListingUpload = (props) => {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const fileReader = new FileReader();
  const { companyId } = useParams();
  const toast = useToast(4000);
  const [loading, setLoading] = useState(false);
  const [notSaved, setNotSaved] = useState([]);
  const [totalSaved, setTotalSaved] = useState("");
  const [results, setResults] = useState(false);
  const [open, setOpen] = useState(false);

  const [mappedColumns, setMappedColumns] = useState(getInitialMappedColumns());

  const [bulkListingUpload, { error, data }] = useMutation(
    BULK_LISTING_UPLOAD,
    {
      refetchQueries: [
        { query: GET_COMPANY_LISTINGS, variables: { companyId } },
      ],
      onCompleted(res) {
        toast("success", "Listing upload has completed!");
        setTotalSaved(res.bulkListingUpload?.totalSaved);
        setNotSaved(res.bulkListingUpload?.notSaved);
        setResults(true);
        setFile("");
        setArray([]);
        props.refetchListings({ companyId });
      },
      onError(e) {
        console.error(e);
        toast(
          "error",
          "There was an error on save. Please check the csv and try again"
        );
      },
    }
  );

  const onCancel = () => {
    props.onCancel();
  };

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header.split(" ").join(" ").trim()] = values[index]
          ?.trim()
          .replace(/['"]+/g, "");
        return object;
      }, {});

      return { ...obj };
    });

    setArray(array);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const handleListingUpload = () => {
    if (window.confirm("Would you like to upload all listings?")) {
      bulkListingUpload({
        variables: { listingBulk: array },
      });
    }
  };

  const getAvailableColumns = () => {
    const columns = ACCEPTED_COLUMNS.map((c) => ({ label: c, value: c }));

    return columns.filter(
      (c) => !mappedColumns.find((mc) => mc.actual === c.label)?.mapped
    );
  };

  const selectColumn = (selected, index) => {
    setMappedColumns((prev) => {
      const temp = [...prev];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].actual === selected) {
          temp[i].mapped = Object.keys(array[0])[index];
        }
      }
      return temp;
    });
  };

  const sanitizeColumnNames = () => {
    const updatedData = array.map((item) => {
      const updatedItem = { CompanyId: companyId };

      mappedColumns.forEach((mapping) => {
        const { actual, mapped } = mapping;

        updatedItem[actual] = item[mapped];
      });

      return updatedItem;
    });

    setOpen(false);

    setArray(updatedData);
  };

  const checkColumnNames = () => {
    if (array.length <= 0) return false;

    let status;

    const validColumNames = mappedColumns.map((s) => s.actual);

    Object.keys(array[0]).map((key) => {
      if (!validColumNames.includes(key)) {
        status = false;
      } else {
        status = true;
      }
    });

    return status;
  };

  return (
    <>
      <Breadcrumb pageName={"Upload Listings"} />
      <div className="px-6">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 p-4">
            <h1 className="text-lg">
              To import multiple listings please upload a CSV file with the
              following columns:
            </h1>
            <ol className="px-2 mt-4">
              <li className="p-2">
                <span className="text-red text-sm">*</span>Expiration (ex:
                yyyy-mm-dd)
              </li>
              <li className="p-2">
                <span className="text-red text-sm">*</span>Street Address (ex:
                Fyrstup Street)
              </li>
              <li className="p-2">
                <span className="text-red text-sm">*</span>City (ex: Ohio)
              </li>
              <li className="p-2">
                <span className="text-red text-sm">*</span>State (ex: Ohio)
              </li>
              <li className="p-2">
                <span className="text-red text-sm">*</span>Zip (ex: 12356)
              </li>
              <li className="p-2">
                <span className="text-red text-sm">*</span>Agent (ex:
                fyrstup.agent@gmail.com)
              </li>
              <li className="p-2">
                <span className="text-red text-sm">*</span>Property Type (ex:
                rent or sale)
              </li>
              <li className="p-2">
                <span className="text-red text-sm">*</span>Price (ex: 20000)
              </li>
              <li className="p-2">Beds (ex: 4)</li>
              <li className="p-2">Baths (ex: 3.5)</li>
              <li className="p-2">Acreage (ex: 0.85)</li>
              <li className="p-2">BuyerCommission (ex: 1500$)</li>
              <li className="p-2">Lot Size (ex: 4000)</li>
              <li className="p-2">Notes (ex: Awesome listing)</li>
              <li className="p-2">YearBuilt (ex: 1999)</li>
              <li className="p-2">SquareFeet (ex: 4000)</li>
            </ol>
            <div className="text-red text-sm ml-4">*required fields</div>

            <div className="flex justify-start align-center space-x-3 items-center mt-4">
              <Button
                onClick={(e) => {
                  setOpen(true);
                  handleOnSubmit(e);
                }}
                size="default"
                className="bg-primary"
              >
                Import CSV
              </Button>
              <Button
                onClick={() => onCancel()}
                size="default"
                className="secondary-btn"
              >
                CANCEL
              </Button>
            </div>
            <input
              type="file"
              className="border rounded p-2 w-3/4 my-3"
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-full md:w-1/2 p-4 bg-light_gray rounded text-xl">
            <h1 className={`${results ? `text-primary` : "text-red"} text-3xl`}>
              Results {results ? " Completed" : " Pending"}
            </h1>
            <ul>
              <li className="py-4">
                Total Listings to Save:{" "}
                {results ? totalSaved + notSaved.length : array.length}
              </li>
              <li className="py-4">
                Total Saved: {results ? totalSaved : "--"}
              </li>
              <li className="py-4">
                Total Not Saved: {results ? notSaved.length : "--"}
              </li>
              <li className="py-4 flex flex-wrap">
                Listings not saved:{" "}
                {results
                  ? notSaved.map((ns, i) => (
                      <div
                        className="px-2"
                        key={i}
                      >{`${ns["StreetAddr"]}, ${ns["City"]}`}</div>
                    ))
                  : "--"}
              </li>
            </ul>
          </div>
        </div>
        <div className="p-4 w-full">
          {loading && (
            <div className="w-full flex text-center justify-center my-4">
              <svg
                aria-hidden="true"
                className="w-8 h-8 mr-2 text-primary animate-spin fill-secondary
                "
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08167 50.5908C9.08167 73.0324 27.5584 91.5091 50 91.5091C72.4416 91.5091 90.9183 73.0324 90.9183 50.5908C90.9183 28.1492 72.4416 9.67249 50 9.67249C27.5584 9.67249 9.08167 28.1492 9.08167 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.723 75.2124 7.55339C69.5422 4.38385 63.2754 2.51526 56.7642 2.08487C51.7661 1.72766 46.7398 2.10211 41.8913 3.19539C39.4057 3.74611 37.9375 6.24859 38.5746 8.67403C39.2117 11.0995 41.694 12.5687 44.1796 12.0179C47.9326 11.166 51.8096 10.9172 55.6186 11.2807C60.4986 11.7575 65.2044 13.4178 69.332 16.1434C73.4595 18.869 76.9101 22.5831 79.364 26.972C81.4635 30.561 83.0086 34.4261 83.9264 38.4415C84.5083 40.7995 87.5422 41.578 89.9676 40.9409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={open}
        title={"Map your columns"}
        onCancel={() => setOpen(false)}
        centered
        footer={() => (
          <div className="mt-3 flex">
            <Button
              key="submit"
              type="primary"
              className="border-primary text-primary w-[73px]"
              onClick={() => sanitizeColumnNames()}
            >
              Save
            </Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </div>
        )}
      >
        <div className="mt-4">
          <Row className="mb-4">
            <Col span={8}>
              <div className="font-semibold">Column Name</div>
            </Col>
            <Col span={8}>
              <div className="font-semibold">Accepted Column</div>
            </Col>
            <Col span={8}>
              <div className="font-semibold">Status</div>
            </Col>
          </Row>
          {array?.length
            ? Object?.keys(array[0])?.map((item, i) => (
                <Row key={i}>
                  <Col span={8}>{item}</Col>
                  <Col span={8}>
                    <Select
                      showSearch
                      placeholder={"Select Option"}
                      options={getAvailableColumns()}
                      onChange={(e) => selectColumn(e, i)}
                      value={
                        mappedColumns.find((mc) => mc.mapped === item)
                          ?.actual || null
                      }
                      className="w-full"
                    />
                  </Col>
                  <Col span={8} className="pl-4">
                    <Button
                      type="default"
                      onClick={() => {
                        setMappedColumns((prev) => {
                          const temp = [...prev];
                          const tIndex = temp.findIndex(
                            (mc) => mc?.mapped === item
                          );
                          if (tIndex > -1) temp[tIndex].mapped = null;
                          return temp;
                        });
                      }}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              ))
            : null}
        </div>
      </Modal>
      {!checkColumnNames() ? null : array.length > 0 ? (
        <>
          <div className="text-2xl my-4 border-b  border-black p-4">
            <span>
              {array.length <= 10
                ? "Preview of the data: "
                : "Preview of the first 10 rows of your data: "}
            </span>
            <button
              onClick={handleListingUpload}
              className="text-blue hover:cursor-pointer hover:text-opacity-80 underline"
            >
              {`Upload all ${array.length} listings?`}
            </button>
          </div>
          <table className="overflow-auto table-fixed">
            <thead>
              <tr className="whitespace-nowrap">
                <th className="w-32">Agent</th>
                <th className="w-32">Expiration</th>
                <th className="w-32">City</th>
                {/* <th>License Number</th> */}
                <th className="w-32">Property Type</th>
                <th className="w-32">State</th>
                <th className="w-48">Street Address</th>
                <th className="w-28">Zip</th>
                <th className="w-28">Price</th>
                <th className="w-16">Beds</th>
                <th className="w-16">Baths</th>
                <th className="w-20">Acreage</th>
                <th className="w-48">BuyerCommission</th>
                <th className="w-16">LotSize</th>
                <th className="w-44">Notes</th>
                <th className="w-16">YearBuilt</th>
                <th className="w-16">SquareFeet</th>
              </tr>
            </thead>
            <tbody>
              {array.slice(0, 10).map((d, i) => (
                <tr className="text-center" key={i}>
                  <td>{d["Agent"]}</td>
                  <td>{d["Expiration"]}</td>
                  <td>{d["City"]}</td>
                  {/* <td>{d["LicenseNumber"]}</td> */}
                  <td>{d["PropertyType"]}</td>
                  <td>{d["State"]}</td>
                  <td>{d["StreetAddr"]}</td>
                  <td>{d["Zip"]}</td>
                  <td>{d["Price"]}</td>
                  <td>{d["Beds"]}</td>
                  <td>{d["Baths"]}</td>
                  <td>{d["Acreage"]}</td>
                  <td>{d["BuyerCommission"]}</td>
                  <td>{d["LotSize"]}</td>
                  <td>{d["Notes"]}</td>
                  <td>{d["YearBuilt"]}</td>
                  <td>{d["SquareFeet"]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
};

export default BulkListingUpload;
