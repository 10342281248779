const Breadcrumb = ({ pageName }) => {
  return (
    <div className="mb-6 flex flex-col gap-3">
      <h2 className="text-title-md2 text-3xl font-semibold text-primary">
        {pageName}
      </h2>
      <div className="block border-b-2 w-full border-primary"></div>
    </div>
  );
};

export default Breadcrumb;
