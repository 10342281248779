import adminRoutes from "../../routes/adminSidebar";
import companyRoutes from "../../routes/companySidebar";
import fyrstuplogo from "../../assets/Fyrst_Up_Logo.png";
import {
	Link,
	NavLink,
	useLocation,
	useNavigate,
	useNavigation,
	useParams,
} from "react-router-dom";
import * as Icons from "../../icons";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
// import SidebarSubmenu from './SidebarSubmenu'

const Icon = ({ icon, ...props }) => {
	const Icon = Icons[icon];
	return <Icon {...props} />;
};

const SidebarContent = () => {
	const location = useLocation();
	const { companyId } = useParams();
	const [routes, setRoutes] = useState([]);
	const { logout } = useContext(AuthContext);
	const navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem("userInfo"));
	const { pathname } = location;
	const splitLocation = pathname.split("/");

	const handleLogout = () => {
		logout();
		navigate("/");
	};

	useEffect(() => {
		location.pathname.includes("/administrator")
			? setRoutes(adminRoutes)
			: setRoutes(companyRoutes);
	}, [location]);

	return (
		<div className="">
			<div className=" p-4">
				<a className="mx-auto" href="#">
					<img className="w-full" src={fyrstuplogo} alt="" />
				</a>
			</div>
			<ul className="mt-6">
				{routes.length > 0 ? (
					routes.map((route) => (
						<li
							className={`${
								splitLocation.find((l) => {
									return l == route.name?.toLowerCase();
								})
									? "bg-primary text-white"
									: "text-secondary"
							} relative px-6 py-3 hover:opacity-80 `}
							key={route.name}
						>
							<NavLink
								to={
									location.pathname.includes("/administrator/")
										? route.path
										: `/company/${companyId}${route.path}`
								}
								className={({ isActive }) => [
									`inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-white-800 `,
									!isActive ? `bg-primary` : "",
								]}
							>
								{/* <span
									className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
									aria-hidden="true"
								></span> */}
								<span>{route.icon}</span>
								{/* <Icon className="w-5 h-5" aria-hidden="true" icon={route.icon} /> */}
								<span className={` ml-4 text-xl`}>{route.name}</span>
							</NavLink>
						</li>
					))
				) : (
					<div></div>
				)}
			</ul>
			<div className="px-6 py-3">
				<Link
					onClick={handleLogout}
					className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800"
				>
					{/* <Icon className="w-5 h-5" aria-hidden="true" icon={'OutlineLogoutIcon'} /> */}
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-6 h-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
							/>
						</svg>
					</span>
					<span className="ml-4 text-secondary text-xl hover:opacity-80">
						Logout
					</span>
				</Link>
			</div>
		</div>
	);
};

export default SidebarContent;
