import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";

const CompanyView = ({ companyData, handleBackClick }) => {
  console.log("Company Data", companyData);

  const details = companyData.details[0];

  return (
    <>
      <div className="w-full pt-10 flex flex-col ">
        <div
          onClick={handleBackClick}
          className="w-full cursor-pointer flex items-center flex-row gap-4"
        >
          <IoIosArrowRoundBack color="#212636" size={30} />
          <p className="font-normal text-[20px] text-[#212636]">Back</p>
        </div>
        <h1 className="my-6 text-[30px] font-medium">{companyData.title}</h1>
        <div className="flex flex-row flex-wrap gap-x-10 border border-gray-200 p-4 rounded-2xl shadow-sm">
          {companyData.details.map((detail, index) => (
            <div
              key={index}
              className="w-[670px] flex flex-col border-2 rounded-2xl my-4"
            >
              <div className="w-full py-3 flex flex-col px-5">
                <h1 className="text-[#667085] font-bold text-[14px]">Name</h1>
                <p className="font-semibold">{detail.name}</p>
              </div>
              <hr />
              <div className="w-full py-3 flex flex-col px-5">
                <h1 className="text-[#667085] font-normal text-[14px]">
                  Designation
                </h1>
                <p className="font-semibold">{detail.position}</p>
              </div>
              <hr />
              <div className="w-full py-3 flex flex-col px-5">
                <h1 className="text-[#667085] font-normal text-[14px]">
                  Location
                </h1>
                <p className="font-semibold">{detail.address}</p>
              </div>
              <hr />
              <div className="w-full py-3 flex flex-col px-5">
                <h1 className="text-[#667085] font-normal text-[14px]">
                  Email
                </h1>
                <p className="font-semibold">{detail.email}</p>
              </div>
              <hr />
              <div className="w-full py-3 flex flex-col px-5">
                <h1 className="text-[#667085] font-normal text-[14px]">
                  Phone Number
                </h1>
                <p className="font-semibold">{detail.phone}</p>
              </div>
              <hr />
              <div className="w-full py-3 flex flex-col px-5">
                <h1 className="text-[#667085] font-normal text-[14px]">
                  Website
                </h1>
                <p className="font-semibold">{detail.url}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CompanyView;
