import { createBrowserRouter } from "react-router-dom";
import ProtectedLayout from "../containers/ProtectedLayout";
import FirstTimeReset from "../pages/FirstTimeReset";
import EditCompany from "../pages/admin/EditCompany";
import AddUser from "../pages/company/AddUser";
import BulkUserUpload from "../pages/company/BulkUserUpload";
import UpdateListing from "../pages/company/UpdateListing";
import UpdateUser from "../pages/company/UpdateUser";

import Layout from "../containers/Layout";
import Page404 from "../pages/404";
import ForgotPassword from "../pages/ForgotPassword";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import AddCompany from "../pages/admin/AddCompany";
import AdminDashboard from "../pages/admin/AdminDashboard";
import Companies from "../pages/admin/Companies";
import Notifications from "../pages/admin/notifications";
import Directories from "../pages/admin/Directories";
import Directory from "../pages/admin/Directory";
import Account from "../pages/company/Account";
import Amenities from "../pages/company/Amenities";
import CompanyDashboard from "../pages/company/CompanyDashboard";
import Listing from "../pages/company/Listing";
import Listings from "../pages/company/Listings";
import Offices from "../pages/company/Offices";
import Users from "../pages/company/UsersNew";
import CompanyDirectories from "../pages/company/Directories";
import CompanyDirectory from "../pages/company/CompanyDirectory";

const companyRoutes = [
  {
    path: "dashboard",
    element: <CompanyDashboard />,
  },
  {
    path: "listings",
    element: <Listings />,
  },
  {
    path: "add-listing",
    element: <Listing />,
  },
  {
    path: "listings/:listingId",
    element: <UpdateListing />,
  },
  {
    path: "offices",
    element: <Offices />,
  },
  {
    path: "users",
    element: <Users />,
  },
  {
    path: "users-upload",
    element: <BulkUserUpload />,
  },
  {
    path: "account",
    element: <Account />,
  },
  {
    path: "add-user",
    element: <AddUser />,
  },
  {
    path: "edit-user/:userId",
    element: <UpdateUser />,
  },
  {
    path: "amenities",
    element: <Amenities />,
  },
  {
    path: "directories",
    element: <CompanyDirectories />,
  },
  {
    path: "directories/:directoryId",
    element: <CompanyDirectory />,
  },
];

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Page404 />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/first-time",
        element: <FirstTimeReset />,
      },
    ],
  },
  {
    path: "/administrator",
    element: <ProtectedLayout />,
    children: [
      {
        path: "dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "companies",
        element: <Companies />,
      },
      {
        path: "add-company",
        element: <AddCompany />,
      },
      {
        path: "edit-company/:companyId",
        element: <EditCompany />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "directories",
        element: <Directories />,
      },
      {
        path: "directories/:directoryId",
        element: <Directory />,
      },
    ],
  },
  {
    path: "/company/:companyId",
    element: <ProtectedLayout />,
    children: companyRoutes,
  },
]);

export default router;
