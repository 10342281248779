import { useMutation } from "@apollo/client";
import {
  ADD_DIRECTORY_LIST,
  EDIT_DIRECTORY_LIST,
} from "../../graphql/directoryQueries";
import { useToast } from "../Toast/toastHook";
import { useEffect, useRef, useState } from "react";
import { Formik, FieldArray } from "formik";
import Input from "../Input";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const DirectoryListForm = ({
  directoryListId,
  company,
  formType,
  handleClose,
  onDirectoryListAdded,
}) => {
  const [initialValues, setInitialValues] = useState({
    title: "",
    details: [
      {
        address: "",
        email: "",
        name: "",
        phone: "",
        position: "",
        url: "",
      },
    ],
  });

  const toast = useToast(3000);
  const formikRef = useRef();

  const [addDirectoryList, { loading: addLoading }] = useMutation(
    ADD_DIRECTORY_LIST,
    {
      onCompleted(result) {
        toast(
          "success",
          `Successfully saved ${result.addDirectoryList?.title}!`
        );
        formikRef.current?.resetForm();
        handleClose();
        onDirectoryListAdded();
      },
      onError(e) {
        toast("error", "There was an issue saving the data, please try again");
      },
    }
  );

  const [editDirectoryList, { loading: editLoading }] = useMutation(
    EDIT_DIRECTORY_LIST,
    {
      onCompleted(result) {
        toast(
          "success",
          `Successfully updated ${result.editDirectoryList?.title}!`
        );
        formikRef.current?.resetForm();
        handleClose();
        onDirectoryListAdded();
      },
      onError(e) {
        toast(
          "error",
          "There was an issue updating the data, please try again"
        );
      },
    }
  );

  const handleFormData = () => {
    if (formType === "edit" && company) {
      setInitialValues({
        title: "",
        details: [
          {
            name: "",
            address: "",
            email: "",
            phone: "",
            position: "",
            url: "",
          },
        ],
      });
    } else {
      formikRef.current?.resetForm();
    }
  };

  useEffect(() => {
    handleFormData();
  }, [company, formType]);

  const handleSave = (values) => {
    const { title, details } = values;

    if (formType === "add") {
      addDirectoryList({
        variables: {
          directoryListInput: {
            data: [
              {
                title,
                details,
              },
            ],
            directory_id: directoryListId,
          },
        },
      });
    } else {
      editDirectoryList({
        variables: {
          directoryListInput: {
            title,
            details,
            directory: directoryListId,
          },
        },
      });
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values) => {
        handleSave(values);
      }}
    >
      {({ values, errors, isSubmitting, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-3 flex flex-col gap-6">
            <div className="w-full">
              <Input
                label={"Title"}
                name="title"
                error={errors.title}
                value={values.title}
                onChange={handleChange}
                type="text"
                placeholder={"Title"}
              />
            </div>
            <FieldArray name="details">
              {({ push }) => (
                <>
                  {values.details.map((detail, index) => (
                    <div key={index} className="mt[-30px]">
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Name"}
                          name={`details[${index}].name`}
                          error={errors.details?.[index]?.name}
                          value={detail.name}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Name"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Designation"}
                          name={`details[${index}].position`}
                          error={errors.details?.[index]?.position}
                          value={detail.position}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Designation"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Location"}
                          name={`details[${index}].address`}
                          error={errors.details?.[index]?.address}
                          value={detail.address}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Location"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Email"}
                          name={`details[${index}].email`}
                          error={errors.details?.[index]?.email}
                          value={detail.email}
                          onChange={handleChange}
                          type="email"
                          placeholder={"Email"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Phone Number"}
                          name={`details[${index}].phone`}
                          error={errors.details?.[index]?.phone}
                          value={detail.phone}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Phone Number"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Website"}
                          name={`details[${index}].url`}
                          error={errors.details?.[index]?.url}
                          value={detail.url}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Website"}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-end w-full">
                    <button
                      type="button"
                      onClick={() => {
                        push({
                          address: "",
                          email: "",
                          name: "",
                          phone: "",
                          position: "",
                          url: "",
                        });
                      }}
                      className="flex w-[150px] rounded-xl h-[42px] pb-10 justify-center bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
                    >
                      Add+
                    </button>
                  </div>
                </>
              )}
            </FieldArray>
          </div>

          <div className="mt-6 flex space-x-2">
            <button
              type="button"
              disabled={addLoading || editLoading}
              onClick={() => handleClose()}
              className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={isSubmitting || addLoading || editLoading}
              className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
            >
              {addLoading || editLoading ? (
                <div className="animate-spin">
                  <AiOutlineLoading3Quarters size={22} />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default DirectoryListForm;
