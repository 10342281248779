import { useQuery } from "@apollo/client";
import useDebounce from "../hooks/useDebounce";
import {
  GET_DETAILED_ADDRESS,
  GET_SIMILAR_ADDRESSES,
} from "../graphql/listingQueries";
import { useState } from "react";
import { useToast } from "./Toast/toastHook";
import { AutoComplete as AutoCompleteAnt } from "antd";

const AutoComplete = ({ values, errors, setFieldValue }) => {
  const [options, setOptions] = useState([]);
  const [placeId, setPlaceId] = useState("");
  const debouncedValue = useDebounce(values["streetAddr"], 200);
  const toast = useToast(300);
  useQuery(GET_DETAILED_ADDRESS, {
    variables: { placeId },
    onCompleted(result) {
      autofillListingFields(result?.getDetailedAddress || {});
    },
    onError(err) {
      console.log("error in AutoComplete/GET_DETAILED_ADDRESS", err);
    },
  });

  const autofillListingFields = ({ city, zipCode, state, shortAddress }) => {
    setFieldValue("city", city);
    setFieldValue("state", state);
    setFieldValue("zip", zipCode);
    setFieldValue("streetAddr", shortAddress);
  };

  const { data } = useQuery(GET_SIMILAR_ADDRESSES, {
    variables: {
      address: debouncedValue,
    },
    onCompleted: (data) => {
      setOptions(
        data.autocomplete.map((value) => ({
          label: value.description,
          value: value.placeId,
        }))
      );
    },

    onError: (error) => {
      console.log("error in autocomplete", error);
      toast("error", error.message);
    },
  });

  const onSelect = (value) => {
    console.log({ value });
    setPlaceId(value);
  };

  const onSearch = (value) => {
    setFieldValue("streetAddr", value);
  };

  return (
    <>
      <label className="mb-2 block text-black ">Address</label>
      <AutoCompleteAnt
        options={options}
        placeholder="Enter the street address"
        onSelect={onSelect}
        onSearch={onSearch}
        value={values["streetAddr"]}
        className="w-full h-[50px]"
      />
      {errors.streetAddr && (
        <div className="py-1 text-xs text-red">{errors.streetAddr}</div>
      )}
    </>
  );
};

export default AutoComplete;
