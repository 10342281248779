import { useMutation } from "@apollo/client";
import { useToast } from "../Toast/toastHook";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { DELETE_DIRECTORY_LIST } from "../../graphql/directoryQueries";

const CompanyDeleteView = ({ companyId, handleClose, setShowDeleteModal }) => {
  const toast = useToast(3000);

  const [deleteDirectoryList, { loading: deleteLoading }] = useMutation(
    DELETE_DIRECTORY_LIST
  );

  const handleCancelClick = () => {
    setShowDeleteModal(false);
  };

  // Function to call the delete mutation
  const handleDeleteDirectory = async (companyId) => {
    try {
      const { data } = await deleteDirectoryList({
        variables: {
          deleteDirectoryListDirectoryListInput2: { _id: companyId }, // Adjust the input as per your schema
        },
      });

      if (data.deleteDirectoryList) {
        toast("success", "Company deleted successfully");
        console.log("Deleted company ID:", data.deleteDirectoryList._id);
        handleClose();
      } else {
        toast("error", "Company not deleted or does not exist");
        handleClose();
      }
    } catch (error) {
      console.error("Error deleting company:", error);
      toast("error", "Failed to delete company");
      handleClose();
    }
  };

  return (
    <form>
      <p>Are you sure you want to delete this Company?</p>
      <div className="mt-6 flex space-x-2">
        <button
          type="button"
          disabled={deleteLoading}
          onClick={() => handleDeleteDirectory(companyId)}
          className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
        >
          {deleteLoading ? (
            <div className="animate-spin">
              <AiOutlineLoading3Quarters size={22} />
            </div>
          ) : (
            "Yes"
          )}
        </button>
        <button
          onClick={handleCancelClick}
          disabled={deleteLoading}
          className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default CompanyDeleteView;
