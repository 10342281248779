import { useMutation } from "@apollo/client";
import { useToast } from "../Toast/toastHook";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { DELETE_DIRECTORY } from "../../graphql/directoryQueries";

const DirectoryDeleteView = ({
  directorytId,
  handleClose,
  setShowDeleteModal,
}) => {
  const toast = useToast(3000);

  const addLoading = false;

  const handleCancelClick = () => {
    setShowDeleteModal(false);
  };

  console.log("Directory ID in delete view", directorytId);

  const [deleteDirectory, { loading: deleteLoading }] =
    useMutation(DELETE_DIRECTORY);

  // Function to call the delete mutation
const handleDeleteDirectory = async (directoryId) => {
  try {
    const { data } = await deleteDirectory({
      variables: {
        deleteDirectoryDirectoryInput2: { _id: directoryId },
      },
    });

    if (data.deleteDirectory) {
      toast("success", "Directory deleted successfully");
            handleClose();

    } else {
      toast("error", "Directory not deleted");
      handleClose()
    }
  } catch (error) {
    toast("error", "Failed to delete directory");
          handleClose();

  }
};


  return (
    <form>
      <p>Are you sure you want to delete this directory?</p>
      <div className="mt-6 flex space-x-2">
        <button
          type="button"
          disabled={addLoading}
          onClick={() => handleDeleteDirectory(directorytId)}
          className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
        >
          {deleteLoading ? (
            <div className="animate-spin">
              <AiOutlineLoading3Quarters size={22} />
            </div>
          ) : (
            "Yes"
          )}
        </button>
        <button
          onClick={handleCancelClick}
          disabled={deleteLoading}
          className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default DirectoryDeleteView;
