const AutocompleteCheckbox = ({ options, rootKey, formik, dropdownRef }) => {
  const toggleOpen = () => {
    // if (formik.values.selectedItems[rootKey].length <= 0) {
    formik.setFieldValue(`isOpen.${rootKey}`, !formik.values.isOpen[rootKey]);
    // }
    formik.setFieldValue(
      `isOpen.${rootKey === "company" ? "user" : "company"}`,
      false
    );
  };

  const handleCheckboxChange = (value) => {
    if (formik.values.selectedItems[rootKey].includes(value)) {
      formik.setFieldValue(
        `selectedItems.${rootKey}`,
        formik.values.selectedItems[rootKey].filter((item) => item !== value)
      );
    } else {
      formik.setFieldValue(`selectedItems.${rootKey}`, [
        ...formik.values.selectedItems[rootKey],
        value,
      ]);
    }
  };

  const filteredOptions = options.filter((option) =>
    option[rootKey !== "company" ? "fullName" : "companyName"]
      .toLowerCase()
      .includes(formik.values.search[rootKey].toLowerCase())
  );

  const onSearchChange = (e) => {
    if (!!e.target.value) {
      formik.setFieldValue(`isOpen.${rootKey}`, {
        ...formik.values.isOpen[rootKey],
        [rootKey]: true,
      });
      formik.setFieldValue(`search.${rootKey}`, e.target.value);
    } else {
      formik.setFieldValue(`search.${rootKey}`, e.target.value);
    }
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={formik.values.search[rootKey]}
        onChange={onSearchChange}
        onClick={toggleOpen}
        placeholder={`Search ${rootKey}...`}
        className="w-[350px] rounded-md border-[1px] border-[#d9d9d9] bg-transparent py-3 px-3 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter placeholder:text-sm "
      />
      {formik.values.isOpen[rootKey] && (
        <div
          ref={dropdownRef}
          className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg overflow-auto max-h-96"
        >
          <ul className="py-1">
            {filteredOptions.map((item) => (
              <li
                key={item._id || item.id}
                className="px-4 py-2 flex items-center justify-between hover:bg-gray-100"
              >
                <label className="flex items-start">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-500"
                    value={item}
                    checked={
                      rootKey !== "user"
                        ? formik.values.selectedItems[rootKey].includes(
                            item._id
                          )
                        : formik.values.selectedItems[rootKey].includes(item.id)
                    }
                    onChange={() =>
                      rootKey !== "user"
                        ? handleCheckboxChange(item._id)
                        : handleCheckboxChange(item.id)
                    }
                  />
                  <span className="ml-2 text-sm">
                    {item[rootKey !== "company" ? "fullName" : "companyName"]}
                  </span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AutocompleteCheckbox;
